import React, { useState } from 'react'
import Button from '../common/Button'

export default function NewsletterCTA() {
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = () => {
    setSubmitted(true)
  }

  return (
    <div className="bg-uforange-600 py-10 sm:py-16 lg:py-24">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div className="max-w-xl text-3xl font-bold tracking-tight text-white sm:text-4xl lg:col-span-7">
          <h2 className="block">
            Want to hear about the latest news, updates and events from Urban
            Fox?
          </h2>
          <h3 className="block mt-4">Sign up for our newsletter now.</h3>
        </div>
        <form
          className="w-full max-w-md lg:col-span-5 lg:pt-2"
          name="subscribers"
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="subscribers" />
          <div className="flex gap-x-4">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="min-w-0 flex-auto rounded-md border-0 bg-white/10 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-white/75 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
              placeholder="Enter your email"
            />
            <Button type="submit" label="Subscribe" variant="secondary" />
          </div>
          {submitted && (
            <p className="text-center w-full text-white mt-4">
              Thank you for subscribing!
            </p>
          )}
        </form>
      </div>
    </div>
  )
}
