import React from 'react'
import Header from '../components/common/Header'
import AppCTA from '../components/cta/AppCTA'
import GeneralCTA from '../components/cta/GeneralCTA.js'
import NewsletterCTA from '../components/cta/NewsletterCTA'
import Features from '../components/home/Features'
import Layout from '../components/layout'
class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Header filter="Home" />
        <Features />
        <NewsletterCTA />
        <AppCTA />
        <GeneralCTA />
      </Layout>
    )
  }
}

export default RootIndex
