import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import FeatureItem from '../common/FeatureItem'

export default function Features() {
  const data = useStaticQuery(graphql`
    query homeFeaturesQuery {
      allContentfulFeatureItem(
        sort: { fields: [order], order: ASC }
        filter: { page: { eq: "Home" } }
      ) {
        nodes {
          title
          description {
            raw
          }
          order
          imageAlt
          icon
          flipped
          actionLabel
          actionLink
          showGif
          image {
            gatsbyImage(layout: FULL_WIDTH, width: 1920, height: 1080)
          }
        }
      }
    }
  `)

  const content = get(data, 'allContentfulFeatureItem.nodes', null)

  return (
    <div className="relative overflow-hidden bg-white">
      <div className="relative max-w-7xl mx-auto">
        {content.map((item) => (
          <FeatureItem feature={item} key={item.title} />
        ))}
      </div>
    </div>
  )
}
